@import "~react-vis/dist/style";



@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  @variants responsive {
    .text-shadow {
      text-shadow: 2px 4px 9px rgba(0, 0, 0, 0.3);
    }
    .text-shadow-md {
      text-shadow: 4px 4px 6px black;
    }
    .text-shadow-lg {
      text-shadow: 15px 15px 15px black;
    }
    .text-shadow-none {
      text-shadow: none;
    }
  }
}
//check to remove these from @tpsengage/hour-parting-scheduler
@layer base {
  body {
    // background: #f3f3f3 !important;
    font-family: 'Raleway', 'sans-serif' !important;
    @apply bg-white;
  }

  .custom-body {
    @apply bg-f3f3f3 dark:bg-dark-default;
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

@layer components {
  :root {
    --accent-hue: #2b3e50;
    --contrast-hue: #ff9800;
    --text-hue: #403b33;
    --secondary-accent: #fe0557;
    --green: #05cd99;
    --greenOp: #05cd994d;
    --contrast-green: #cfdae6;
    --dark: #3b4958;
    --dark-accent: #c1c5ca1a;
    --primary: #f53d3f;
    --light: #dadde0;
    --body: #858d96;
    --whiteish: #f3f3f3;
    --orange: #ed8712;
    --orangeOp: #ed871233;
    --info: #1f8af4;
    --infoOp: #1f8af433;
    --danger: #f53d3f;
    --dangerOp: #f53d3f33;
  }

  /* Stilizare scrollbar pentru Webkit (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    @apply dark:bg-dark-200 bg-chartBgGrey;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 1px solid #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Stilizare scrollbar pentru Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
  }

  /* Stilizare scrollbar pentru Microsoft Edge */
  ::-ms-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-ms-scrollbar-track {
    background: red;
  }

  ::-ms-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }

  ::-ms-scrollbar-thumb:hover {
    background: #555;
  }

  html {
    color: var(--text-hue);
    overscroll-behavior-y: none;
    @media screen and (max-height: 790px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 13px;
    }
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  .navbar-right-padding {
    @media screen and (max-width: 1024px) {
      padding-right: 4rem;
    }
  }

  html,
  body {
    height: 100%;
  }

  #__next {
    height: 100%;
  }
  button {
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  }

  body {
    overflow-y: scroll;
    @apply font-display;
    // @apply bg-grey-100;
    overscroll-behavior-y: none;
    overflow: overlay;
  }

  // ::selection {
  //   background: var(--secondary-accent);
  //   color: white !important;
  // }

  /* Change the white to any color */
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .btn-base {
    @apply text-lg md:text-base px-7 py-4 md:py-3 text-white border appearance-none rounded-4xl md:rounded-3xl hover:ring-0 focus:ring-0 transition duration-150 ease-linear tracking-wide cursor-pointer;
    &.just-icon {
      @apply px-3 py-3 rounded-2xl;
    }
    &.small {
      @apply px-3 py-2 text-sm md:text-xs font-semibold min-w-16 rounded-3xl;
    }

    &.large {
      @apply px-8 py-4 rounded-4xl;
    }
  }

  label {
    @apply text-display;
  }
  input {
    @apply text-display;
  }
  .btn-base-small {
    @apply px-2;
    @apply text-sm;
    @apply leading-7;
    @apply transition;
    @apply duration-150;
    @apply ease-linear;
    @apply border-2;
    @apply border-transparent;
    @apply border-solid rounded;
  }

  .btn-base-large {
    @apply leading-10;
    @apply transition;
    @apply duration-150;
    @apply ease-linear;
    @apply border-2;
    @apply border-transparent;
    @apply border-solid;
    @apply rounded px-9;
  }

  .animated {
    @apply transition;
    @apply duration-200;
    @apply ease-in-out;
  }

  /* HYPE-UNDERLINE */
  .hype-underline-expand > * {
    transition: transform 0.2s ease;
    transform: translate3d(0, 0, 0);
  }

  .hype-underline-expand::before,
  .hype-underline-expand::after {
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0%;
    height: 100%;
    transition: width 0.3s ease;
    border-bottom: 2px solid;
    border-colorx: currentColor;
  }

  .hype-underline-expand::before {
    bottom: 0;
    right: 50%;
  }

  .hype-underline-expand::after {
    bottom: 0;
    left: 50%;
  }

  .hype-underline-expand:hover::before,
  .hype-underline-expand:hover::after {
    width: 50%;
  }

  /* HYPE-BRACKETS */
  .hype-brackets::before,
  .hype-brackets::after {
    position: absolute;
    top: 0;
    font-size: 150%;
    line-height: 1;
    opacity: 0;
    transition:
      opacity 0.2s,
      transform 0.2s;
    font-weight: 900;
  }

  .hype-brackets::before {
    left: -0.2em;
    top: -1px;
    content: '[';
    transform: translateX(-100%);
  }

  .hype-brackets::after {
    right: -0.2em;
    top: -1px;
    content: ']';
    transform: translateX(100%);
  }

  .hype-brackets:hover::before,
  .hype-brackets:hover::after {
    transform: translateX(0);
    opacity: 1;
  }

  .hype-scaleup::after {
    content: '';
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: red;
    z-index: -1;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(0);
    transition: all 0.2s ease;
    border-radius: 5px;
  }

  .hype-scaleup:hover {
    color: white;
  }

  .hype-scaleup:hover::after {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }

  .primary.hype-scaleup::after {
    @apply bg-primary-500;
  }

  .accent.hype-scaleup::after {
    @apply bg-accent-500;
  }

  .neutral.hype-scaleup::after {
    background: var(--text-hue);
  }

  .warn.hype-scaleup::after {
    @apply bg-warn-500;
  }

  .danger.hype-scaleup::after {
    @apply bg-danger-500;
  }

  .success.hype-scaleup::after {
    @apply bg-success-500;
  }

  /* TOGGLE INPUT */
  .toggle input:before {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: calc(0.25rem - 1px);
    left: calc(0.25rem - 1px);
    transform: scale(1);
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.2);
    background-color: white;
    transition: 0.2s ease-in-out;
    user-select: none;
  }

  .toggle input:checked {
    @apply bg-indigo-400;
    background-color: #7f9cf5;
    background-image: none;
  }

  .primary.toggle input:checked {
    @apply bg-primary-500;
  }

  .accent.toggle input:checked {
    @apply bg-accent-500;
  }

  .neutral.toggle input:checked {
    background: var(--text-hue);
  }

  .warn.toggle input:checked {
    @apply bg-warn-500;
  }

  .danger.toggle input:checked {
    @apply bg-danger-500;
  }

  .success.toggle input:checked {
    @apply bg-success-500;
  }

  .toggle input:checked:before {
    left: 1.75rem;
  }

  .collapsible-error {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;
  }

  .expanded.collapsible-error {
    max-height: 50px;
  }

  .icon-vertical-fix {
    vertical-align: -0.34em;
  }

  .border-light {
    border: 1px solid var(--contrast-hue);
  }

  /* TEXT INPUT */
  // .text-display input {
  //   outline: none;
  // }

  // .text-display input::placeholder {
  //   opacity: 0;
  // }

  .text-display input:focus + label {
    @apply transform;
    @apply -translate-y-8 left-3.5;
  }
  // .special-label {
  //   @apply text-xs;
  // }
  .text-display input:not(:placeholder-shown) + label,
  .select-box-label {
    @apply transform;
    @apply -translate-y-8 left-3.5;
  }
  .input-simulate {
    @apply bg-transparent    placeholder-transparent    outline-none    text-lightGrey    font-medium    w-full    px-4 py-2    border-0    border-grey-100 dark:border-grey-default dark:border-opacity-40   border-t-0 border-l-0 border-r-0 border-b-2    animated    focus:ring-0    hover:border-primary-500    focus:border-primary-500;
    &.border-light {
      border-color: var(--whiteish);
    }
  }
  .input-simulate,
  .chevron-transform {
    svg {
      @apply transition ease-in-out duration-300;
    }
    &.active svg,
    .active .chevron svg {
      @apply transform rotate-180;
    }
  }
  .select-box {
    [aria-live='polite'] + [class$='control'] {
      div[class$='indicatorContainer'] {
        @apply transform rotate-180;
      }
    }
  }

  /* TEXTAREA */
  .textarea-input textarea {
    outline: none;
  }

  .textarea-input textarea::placeholder {
    opacity: 0;
  }

  .textarea-input textarea:focus + label {
    @apply transform;
    @apply scale-75;
    @apply -translate-x-4;
    @apply -translate-y-8;
    color: currentColor;
    @apply text-sm;
  }

  .textarea-input textarea:not(:placeholder-shown) + label {
    @apply transform;
    @apply scale-75;
    @apply -translate-x-4;
    @apply -translate-y-8;
    color: currentColor;
    @apply text-sm;
  }

  .textarea-input textarea:focus + label[class*='left-10'] {
    @apply transform;
    @apply scale-75;
    @apply -translate-x-10;
    @apply -translate-y-8;
    color: currentColor;
    @apply text-sm;
  }

  /* LAYOUT */
  .mainLayout {
    @apply h-full relative;
    padding-top: 40px;
  }
  .mobile-overlay {
    @apply absolute;
    @apply left-0;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-around;
    @apply w-full;
    @apply px-10;
    @apply bg-white;
    height: calc(100vh - theme('spacing.14'));
    top: theme('spacing.14');
  }

  .filter-shadow-dark {
    box-shadow:
      0px 0px 0px rgba(0, 0, 0, 0.5),
      0px 16px 20px rgba(0, 0, 0, 0.3);
  }

  .filter-shadow-light {
    box-shadow:
      0px 0px 0px rgba(154, 170, 207, 0.15),
      0px 16px 40px rgba(154, 170, 207, 0.15);
  }

  .dark-shadow {
    box-shadow:
      0px -1px 10px rgba(0, 0, 0, 0.5),
      0px 16px 40px rgba(0, 0, 0, 0.3) !important;
  }

  header {
    height: 100%;
    grid-area: header;
  }

  footer {
    background: purple;
    width: 100%;
    height: 100%;
    grid-area: footer;
    min-height: 60px;
  }

  main {
    @apply flex;
    @apply justify-center;
    @apply w-full;
    @apply px-4;
    @apply mx-auto;
    @apply md:max-w-5xl;
    @apply lg:max-w-6xl;
    @apply xl:max-w-7xl;
  }

  @screen md {
    .mainLayout {
    }
  }

  .flex-wrapped-container {
    @apply flex;
    @apply flex-wrap;
    @apply justify-center;
    @apply mx-auto;
    @apply space-x-4;
    @apply -mb-4;
  }

  .flex-wrapped-container > * {
    @apply mb-4;
  }

  .select-box {
    @apply w-full;
  }
  .campaign-widgets-box {
    svg {
      @apply text-contrast-500;
    }
  }
  .status-info {
    @apply absolute top-2 right-2  z-50 flex content-center text-white text-xs font-semibold;
    .status-info-text {
      @apply ease-in-out duration-700 transition-all tracking-wide flex items-center bg-opacity-70 rounded-xl absolute opacity-0  right-0 pr-8 pl-2 h-full;
    }
    &:hover {
      .status-info-text {
        @apply opacity-100;
      }
    }
  }
  .popover-wrapper {
    @apply relative;
    &.leftbottom {
      .popover-content {
        @apply right-unset left-0;
        @media screen and (max-width: 1330px) {
          @apply right-unset left-0;
          // left: -130px;
        }
      }
    }
    &.basket {
      .popover-content {
        @media screen and (max-width: 768px) {
          @apply right-unset -right-20;
        }
      }
    }
    &.leftbottom1 {
      .popover-content {
        @apply right-unset left-0;
        @media screen and (max-width: 1330px) {
          @apply right-unset left-0;
        }
      }
    }
    .popover-content {
      @apply absolute z-101 block rounded-2xl bg-white dark:bg-dark-200 min-w-10  max-h-100-58 overflow-y-auto right-0 top-full mt-5 p-3;
      box-shadow:
        0px -1px 10px rgba(154, 170, 207, 0.15),
        0px 16px 40px rgba(154, 170, 207, 0.15);
      .popoever-cart-menu-items {
        li:not(:last-of-type) {
          @apply pb-2;
        }
      }
    }
    // @media screen and (max-width: 1330px) {
    //   .popover-content {
    //     right: 0;
    //     bottom: auto;
    //     top: 72px;
    //     width: 100vw;
    //     left: -30px;
    //     &:after {
    //       display: none;
    //     }
    //   }
    // }
    // @media screen and (min-width: 800px) {
    //   &.right {
    //     .popover-content {
    //       @apply left-auto;
    //       right: -10px;
    //       &:after {
    //         @apply left-auto;
    //         right: 10px;
    //         bottom: -13px;
    //       }
    //     }
    //   }
    //   &.leftbottom {
    //     .popover-content {
    //       @apply right-auto;
    //       left: 0px;
    //       bottom: auto;
    //       top: 60px;
    //       &:after {
    //         content: '';
    //         @apply w-0 h-0   absolute;
    //         border-top: 0;
    //         border-bottom: 14px solid #fff;
    //         border-left: 14px solid transparent;
    //         border-right: 14px solid transparent;
    //         left: auto;
    //         left: 10px;
    //         bottom: auto;
    //         top: -13px;
    //       }
    //     }
    //   }

    //   &.centerbottom {
    //     .popover-content {
    //       @apply bg-accent-300;
    //       @apply right-auto;
    //       right: 0;
    //       bottom: auto;
    //       top: 72px;

    //       left: auto;
    //       &:after {
    //         content: '';
    //         @apply w-0 h-0   absolute;
    //         border-top: 0;
    //         border-bottom: 14px solid #ffc947;
    //         border-left: 14px solid transparent;
    //         border-right: 14px solid transparent;
    //         left: auto;
    //         right: 10px;
    //         bottom: auto;
    //         top: -13px;
    //       }
    //     }
    //   }
    //   &.rightbottom {
    //     .popover-content {
    //       @apply right-auto;
    //       right: -10px;
    //       bottom: auto;
    //       top: 52px;
    //       left: auto;
    //       &:after {
    //         content: '';
    //         @apply w-0 h-0   absolute;
    //         border-top: 0;
    //         border-bottom: 14px solid #fff;
    //         border-left: 14px solid transparent;
    //         border-right: 14px solid transparent;
    //         left: auto;
    //         right: 10px;
    //         bottom: auto;
    //         top: -13px;
    //       }
    //     }
    //   }
    //   &.noarrow {
    //     .popover-content {
    //       &:after {
    //         display: none !important;
    //       }
    //     }
    //   }
    //   &.rightmiddle {
    //     .popover-content {
    //       left: 107%;
    //       right: auto;
    //       bottom: auto;
    //       top: 0%;
    //       &:after {
    //         content: '';
    //         @apply w-0 h-0   absolute;
    //         border-top: 0;
    //         border-bottom: 14px solid #fff;
    //         border-left: 14px solid transparent;
    //         border-right: 14px solid transparent;
    //         left: right;
    //         left: -19px;
    //         bottom: auto;
    //         top: 12px;
    //         transform: rotate(270deg);
    //       }
    //     }
    //   }
    //   &.leftmiddle {
    //     .popover-content {
    //       right: 107%;
    //       left: auto;
    //       bottom: auto;
    //       top: 0px;

    //       &:after {
    //         content: '';
    //         @apply w-0 h-0   absolute;
    //         border-top: 0;
    //         border-bottom: 14px solid #fff;
    //         border-left: 14px solid transparent;
    //         border-right: 14px solid transparent;
    //         left: auto;
    //         right: -19px;
    //         bottom: auto;
    //         top: 12px;
    //         transform: rotate(-270deg);
    //         @media screen and (max-width: 800px) {
    //           display: none;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  .search-map-inputs {
    @apply w-4/12;

    @media screen and (max-width: 2100px) {
      @apply w-6/12;
    }

    @media screen and (max-width: 1272px) {
      @apply w-8/12;
    }

    @media screen and (max-width: 814px) {
      @apply w-10/12;
    }

    @media screen and (max-width: 490px) {
      @apply w-full;
    }
  }

  .popoever-cart-menu-items {
    @apply w-full;
    li {
      @apply flex w-full;
      a,
      label {
        @apply flex py-2 w-full text-yellow-default dark:text-primarydark-default;
        svg {
          @apply fill-[#fda53f] dark:fill-dark;
        }
        &:hover {
          @apply text-primary-800;
        }
        span {
          @apply ml-2;
        }
      }
    }
  }

  .toggle-label {
    @apply mr-2 text-lightGrey text-sm font-medium;
  }
  .toggle-checkbox {
    @apply relative flex align-middle select-none;
    & > input {
      @apply hidden;
    }
    label {
      @apply relative w-12 h-6 bg-grey-100 rounded-full transition duration-300 ease-in-out inline-flex;
      &:before {
        content: '';
        @apply absolute block w-5 h-5 left-1 top-2/4 -translate-y-2/4 bg-white rounded-full border-0  transition-left duration-500 appearance-none cursor-pointer;
      }
    }
    & > input:checked + label {
      @apply bg-primary-500;
      &:before {
        left: calc(100% - theme('spacing.1') - theme('spacing.5'));
      }
    }
    // &.is-white {
    //   label {
    //     @apply bg-black bg-opacity-40;
    //     &:before {
    //       @apply bg-white border-white;
    //     }
    //   }
    //   & > input:checked + label {
    //     @apply bg-primary-800;
    //     &:before {
    //       @apply left-auto -right-1 bg-primary-900 border-primary-900;
    //     }
    //   }
    // }
    // &.is-dark {
    //   label {
    //     @apply bg-black bg-opacity-20;
    //     &:before {
    //       @apply bg-primary-400 border-primary-400;
    //     }
    //   }
    //   & > input:checked + label {
    //     @apply bg-primary-600;
    //     &:before {
    //       @apply left-auto -right-1 bg-primary-800 border-primary-800;
    //     }
    //   }
    // }
  }
  .radioButtonList {
    input {
      @apply hidden;
    }
    div {
      @apply w-1/2 md:w-auto inline-flex items-center cursor-pointer;
    }
    label {
      @apply rounded-xl text-body cursor-pointer w-full text-sm flex items-center transition duration-300 ease-in-out;
      &.is-disabled {
        @apply cursor-default bg-grey-200 bg-opacity-40 text-whiteish;
      }
      &.is-dark {
        @apply text-primary-800  bg-primary-400 border-primary-400;
        &:before {
          @apply bg-primary-400 border-primary-400;
        }
      }
      svg {
        @apply bg-transparent rounded transition ease-in-out duration-300;
      }
    }
    &:not(.just-icons) {
      label {
        @apply px-5 py-2;
        &.small {
          @apply px-3;
        }
      }
    }
    input:checked + label {
      @apply text-primary-500 bg-white dark:bg-dark-300 dark:bg-opacity-20 font-semibold;
      svg {
        @apply text-primary-500 bg-white dark:bg-dark-200  shadow-card;
      }
      &.is-dark {
        @apply text-white bg-primary-500;
      }
    }
    &.is-dark {
      @apply text-primary-800;
      &:before {
        @apply bg-primary-900 border-primary-900;
      }
    }
  }
  .excl-mark {
    @apply w-5 h-5 -top-2 -right-2 bg-accent-500 text-center rounded-full text-white text-xs leading-5;
  }

  .excl-mark-footer {
    @apply w-6 h-6 top-3 bg-accent-500 text-center rounded-full text-white text-lg leading-5;
    right: 8rem;
    padding-top: 2px;
  }

  .valid-mark-footer {
    @apply w-6 h-6 top-3 bg-success-default text-center rounded-full text-white text-lg leading-5;
    right: 8rem;
    padding-top: 2px;
  }

  .close-modal-alert {
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 100%;
    background-color: #fff;
    padding: 3px;
  }

  .seewhat {
    text-decoration: underline;
    text-align: end;
    color: #fff;
    margin-left: 5px;
    color: #fff;
    font-weight: 500;

    @apply hover:text-primarydark-default;
  }

  .list-grid-cols {
    @apply grid-cols-5;
    @media screen and (max-width: 2100px) {
      @apply grid-cols-4;
    }

    @media screen and (max-width: 1272px) {
      @apply grid-cols-3;
    }

    @media screen and (max-width: 814px) {
      @apply grid-cols-2;
    }

    @media screen and (max-width: 490px) {
      @apply grid-cols-1;
    }
  }

  .half-list-grid-cols {
    @apply grid-cols-3;
    @media screen and (max-width: 2138px) {
      @apply grid-cols-2;
    }
    @media screen and (max-width: 951px) {
      @apply grid-cols-1;
    }
  }

  .tps-blindspot-alert {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    opacity: 0.99;
    @media screen and (max-width: 370px) {
      font-size: 9px;
    }
  }

  // .pulse-valid-icon {
  //   font-weight: 700;
  //   transform: scale(1);
  //   animation: pulse-valid 2s infinite;
  // }

  // .pulse-disclaimer {
  //   font-weight: 700;
  //   transform: scale(1);
  //   animation: pulse-dis 2s infinite;
  // }

  .formerly {
    font-size: 10px;
    font-weight: 600;
    @apply text-dark-default dark:text-dark-400;
    margin: auto;
  }

  @keyframes pulse-valid {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(5, 205, 153, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 7px rgba(5, 205, 153, 0.1);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(5, 205, 153, 0.1);
    }
  }

  @keyframes pulse-dis {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 152, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 7px rgba(255, 152, 0, 0.1);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 152, 0, 0.1);
    }
  }

  .checked-mark {
    @apply w-5 h-5 -top-2 -right-2 bg-success-500 text-center rounded-full text-white text-xs leading-5;
  }

  .checked-mark-red {
    @apply w-5 h-5 -top-2 -right-2 bg-accent-200 text-center rounded-full text-white text-xs leading-5;
  }
  .classic-radio {
    [type='radio']:not(.disabled) {
      &:checked {
        background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' fill='%23F53D3F' xmlns='http://www.w3.org/2000/svg'><circle cx='8' cy='8' r='3'/></svg>");
        background-color: #fff;
      }

      &[color='accent'] {
        &:checked {
          background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' fill='%23FF9800' xmlns='http://www.w3.org/2000/svg'><circle cx='8' cy='8' r='3'/></svg>");
        }
      }
      &[color='warn'] {
        &:checked {
          background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' fill='%23FDA53F' xmlns='http://www.w3.org/2000/svg'><circle cx='8' cy='8' r='3'/></svg>");
        }
      }
      &[color='success'] {
        &:checked {
          background-image: url("data:image/svg+xml;utf8<svg viewBox='0 0 16 16' fill='%2305CD99' xmlns='http://www.w3.org/2000/svg'><circle cx='8' cy='8' r='3'/></svg>");
        }
      }
    }
  }
  .checkbox.just-text,
  .radio.just-text {
    &:checked + h6 {
      @apply bg-primary-500 text-white font-bold;
    }
  }
  .tooltip-chart {
    @apply p-2 text-dark-default text-sm leading-loose;
    strong {
      @apply font-semibold;
    }
  }
  .chart-no-data {
    background: url('/images/no-data.svg') left center no-repeat;
    background-size: contain;
    * {
      color: transparent !important;
    }
  }
  .strength-meter {
    @apply relative h-1.5 bg-gray-500 bg-dark-400 bg-opacity-75 rounded mx-2;
  }
  .strength-meter:before,
  .strength-meter:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    @apply border-white dark:border-dark-200 border absolute z-10;
    border-width: 0 6px 0;
    width: calc(20% + 6px);
  }
  .strength-meter:before {
    left: calc(20% - 3px);
  }
  .strength-meter:after {
    right: calc(20% - 3px);
  }

  .strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition:
      width 0.5s ease-in-out,
      background 0.25s;
  }
  .strength-meter-fill[data-strength='0'] {
    @apply bg-gray-900 dark:bg-white w-1/5;
  }
  .strength-meter-fill[data-strength='1'] {
    @apply w-2/5 bg-red-700;
  }
  .strength-meter-fill[data-strength='2'] {
    @apply w-3/5 bg-contrast-800;
  }
  .strength-meter-fill[data-strength='3'] {
    @apply w-4/5 bg-green-600;
  }
  .strength-meter-fill[data-strength='4'] {
    @apply w-full bg-green-800 rounded-tr rounded-br;
  }
  .menu-holder {
    @apply flex flex-col  w-full justify-between relative bg-white z-10 transition-all duration-300 ease-in-out;

    .is-open & {
      @apply md:h-full  lg:w-72;
    }

    & > div {
      @apply w-full;
      overflow: auto;
    }
  }
  .main-menu {
    @apply border-white  border-opacity-90;
    box-shadow:
      0px 10px 100px 30px rgba(15, 23, 42, 0.05),
      0px 120px 100px -60px rgba(15, 23, 42, 0.1);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(60px);
    }
    .disclaimer-tooltip {
      z-index: 101;
      padding: 0.75rem !important;
      border-radius: 1rem !important;
      background: rgba(255, 255, 255, 1) !important;
      border: 1px solid rgba(236, 239, 245, 1) !important;
      --tw-shadow: 0px 16px 40px rgba(154, 170, 207, 0.2);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      @apply max-lg:hidden;
      &.show {
        opacity: 1;
      }
      &.place-right {
        margin-left: 2rem;
        &::after {
          border-right-color: rgba(255, 255, 255, 1);
        }
      }
    }
    .toggle-button {
      svg {
        @apply ease-in-out transition duration-300;
      }
    }
    &.is-open {
      &:before {
        opacity: 0.4;
      }
      .menu-avatar {
        .name {
          @apply text-lg;
        }
      }
      .menu-header {
        @apply text-left;
      }
      .display-on-extended {
        @apply inline;
      }
      .toggle-button {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
    .menu-items {
      ul {
        li {
          &::before {
            content: '';
            @apply absolute w-1 top-0 -left-9 h-full bg-transparent transition-all duration-300 ease-in-out;
          }

          svg {
            @apply transition-all duration-500 ease-in-out;
          }

          &:not(.toggle-button) {
            p {
              @apply dark:text-dark-400 dark:text-opacity-90;
            }
            .feedback svg {
              path {
                @apply stroke-[#858D96] dark:stroke-[#E4DEDE] fill-[#858D96] dark:fill-[#E4DEDE];
              }
              path:first-child {
                @apply stroke-[#858D96] dark:stroke-[#E4DEDE] fill-[#858D96] dark:fill-[#E4DEDE];
              }
            }
            svg {
              path {
                @apply stroke-[#858D96] dark:stroke-[#E4DEDE];
              }
              path:first-child {
                @apply stroke-[#858D96] dark:stroke-[#E4DEDE];
              }
              path:last-child {
                @apply stroke-[#858D96] dark:stroke-[#E4DEDE];
              }
            }
          }

          &.active {
            &::before {
              @apply bg-yellow-default dark:bg-primarydark-default;
            }
            &:not(.toggle-button) {
              p {
                @apply text-black dark:text-white;
              }
              // svg path {
              //   stroke: #fda53f;
              // }
              .dashboard svg {
                path {
                  @apply stroke-current dark:stroke-dark fill-current dark:fill-dark;
                }
                path:first-child {
                  opacity: 0.4;
                }
              }
              .campaigns svg {
                path {
                  @apply stroke-current dark:stroke-dark;
                }
                path:last-child {
                  @apply fill-current dark:fill-dark;
                  opacity: 0.4;
                }
              }

              .offers svg {
                path {
                  @apply stroke-current dark:stroke-dark;
                }
                path:first-child {
                  opacity: 0.4;
                  @apply fill-current dark:fill-dark;
                }
              }

              .publish svg {
                path {
                  @apply stroke-current dark:stroke-dark;
                }
                path:first-child {
                  @apply fill-current dark:fill-dark;
                }
              }

              .media svg {
                path {
                  @apply stroke-current dark:stroke-dark;
                  @apply fill-current dark:fill-dark;
                }
                path:first-child {
                  opacity: 0.4;
                }
              }
            }
          }

          &:hover {
            // .extra-disclaimer {
            //   display: block;
            // }
            svg {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }

  .menu-opened {
    & + .page-section {
      @apply lg:pl-72;
      .campaigns-grid {
        @apply md:grid-cols-3;
      }
      .container-sticky {
        width: calc(100% - 18rem);
      }
      .fixed-nav {
        width: calc(100% - 18rem);
      }
    }
  }

  .validate-back {
    svg {
      path {
        @apply stroke-[#858D96];
      }
    }
  }

  .center-content {
    @apply h-full justify-center items-center flex;
  }
  .react-code-input {
    input {
      @apply bg-white dark:bg-dark-200 text-dark-default dark:text-dark-400 #{!important};
      font-family: 'Raleway', 'sans-serif' !important;
      // color: #e4dede !important;
      box-shadow: none !important;
      border: 0 !important;
      border-radius: 0 !important;
      text-align: center !important;
      border-bottom: 3px solid #858d96 !important;
      transition: all 0.3s ease-in-out;
      // background-color: #1a1a1a !important;
      // padding-left: 0 !important;
      height: 100% !important;

      &:hover,
      &:focus {
        border-color: #f64747 !important;
        caret-color: #f64747;
      }
      &:focus-visible {
        outline: none !important;
      }
    }
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .blindspot-title {
    font-family: 'Eurostile' !important;
    font-weight: 500 !important;
  }
  .page-section {
    @apply bg-whiteish dark:bg-dark-default relative top-0 left-0 z-50 w-full lg:pl-36 overflow-hidden min-h-full transition-all ease-in-out duration-300;
  }

  .rdt_TableCol div,
  .table-head-th {
    @apply px-2 py-2 text-left text-sm font-medium  uppercase tracking-wider;
    @apply text-primary-200;
    div {
      @apply flex items-center;
      p {
        @apply text-primary-200;
      }
    }
  }
  .rdt_TableCol div {
    @apply text-center w-full justify-center;
  }
  .rdt_TableRow {
    @apply bg-primary-700 text-xs divide-y divide-primary-400;
    .rdt_TableCell:first-child {
      div {
        @apply text-left w-full;
      }
    }
    background-color: #273038 !important;
    .rdt_TableCell {
      @apply text-white text-base text-center;
      div {
        @apply text-center w-full text-sm;
      }
    }
  }
  .table-body-item {
    @apply px-2 py-2 text-sm  text-white;
    .status-info {
      @apply static;
      .status-info-text {
        @apply static opacity-100 text-sm text-center px-2 py-1 w-32 justify-center  font-normal;
      }
      .rounded-full {
        @apply hidden;
      }
    }
  }
  .table-simulate {
    .rdt_Table {
      @apply dark:bg-dark-200;

      div {
        @apply dark:bg-dark-200 dark:text-white;
      }
    }

    .rdt_TableHeadRow {
      // @apply hidden;
    }

    .rdt_TableRow {
      @apply dark:bg-dark-200;
      &:not(:last-of-type) {
        @apply border-0 pb-4;
      }
    }
    & + div > .rdt_Pagination {
      @apply border-0 dark:bg-dark-200 dark:text-dark-400;
      select {
        @apply border border-bordercolor dark:text-white rounded py-2 pr-6 pl-3;
        & + svg {
          @apply hidden;
        }
      }
      svg {
        @apply dark:fill-dark dark:border-2 dark:border-primarydark-default dark:rounded-3xl;
      }
    }
  }

  .table-review.eHijxz {
    @apply rounded-b-none;
    & + div .rdt_Pagination {
      @apply rounded-b-3xl;

      select + svg {
        @apply hidden;
      }
    }
  }

  .table-review {
    .rdt_Table {
      @apply dark:bg-dark-200;
    }

    .rdt_TableHeadRow {
      @apply dark:bg-dark-200 dark:border-b-2 dark:border-b-bordercolordark;

      .rdt_TableCol {
        .rdt_TableCol_Sortable {
          @apply dark:text-white dark:hover:text-dark-400;
        }
      }
    }

    .rdt_TableBody {
      @apply rounded-none;
    }

    .rdt_TableRow {
      @apply dark:bg-dark-200;

      &:not(:last-of-type) {
        @apply border-0 pb-4;
      }

      .rdt_TableCell {
        @apply dark:text-dark-400;
      }
    }

    & + div > .rdt_Pagination {
      @apply border-0 dark:bg-dark-200 dark:text-dark-400 dark:border-t-2 dark:border-t-bordercolordark rounded-b-3xl;

      select {
        @apply border border-bordercolor dark:text-white rounded py-2 pr-6 pl-3;
        & + svg {
          @apply hidden;
        }
      }

      svg {
        @apply dark:fill-dark dark:border-2 dark:border-primarydark-default dark:rounded-3xl;
      }
    }
  }

  .table-mob-actions {
    .popover-handler.active .action,
    .popover-handler:hover .action {
      @apply bg-primary-500;
      svg path {
        fill: #fff;
        stroke: #fff;
      }
    }
  }
  .table-mob {
    &:not(:last-child) {
      @apply pb-4 mb-4 border-b border-bordercolor dark:border-bordercolordark;
    }
  }
  .menu-profile {
    @apply flex w-full py-3 border-b mb-2 overflow-x-scroll md:overflow-visible;
    li {
      flex: 0 0 auto;
      a {
        @apply text-white px-5 relative text-center text-base;
        &:hover,
        &.active {
          @apply text-accent-100;
          &:before {
            content: '';
            @apply w-full h-1 absolute  bg-accent-100 left-0;
            bottom: -14px;
          }
        }
      }
    }
  }

  .white-label {
    label {
      color: #fff !important;
    }
    input {
      color: #fff !important;
    }
  }
  a {
    &.main-btn {
      &.disabled {
        @apply bg-grey-100 cursor-default text-gray-500;
      }
    }
  }
  .breadcrumbs {
    @apply fixed top-0 left-0 flex justify-between w-full pt-12 md:pt-0 md:pl-24 items-center border-primary-300 border-b-2 md:border-b-0;
    background: #2b3c4e;
    z-index: 999;
    .buttons {
      @apply space-x-9;
      a {
        @apply text-primary-500 ml-3;
        &.main-btn {
          @apply text-white;
          &.disabled {
            @apply text-gray-500;
          }
        }
      }
    }
    ul {
      @apply flex space-x-4 pl-2 md:pl-0;
      li {
        @apply text-white text-base md:text-xl text-opacity-70 font-semibold items-center content-center;
        &:last-child {
          @apply text-opacity-100;
        }
      }
    }
    ul.widget-info {
      @apply w-full md:w-64 space-x-0;
      display: block !important;
      overflow: hidden;
      li {
        @apply px-2 py-3 w-full flex justify-between border-b border-white;
        &:last-child {
          @apply border-b-0;
        }
        span {
          @apply text-primary-400 text-left text-base font-normal;
        }
        strong {
          @apply text-white text-xl;
        }
      }
    }
  }
  .bubble {
    @apply absolute top-0 right-0 bg-contrast-800   rounded-full text-xs px-1  text-center;
    right: -20px;
  }
  .widget-header {
    @apply bg-accent-300 px-4 py-1 h-full rounded-tl rounded-bl text-white space-x-4 flex items-center cursor-pointer;
    p {
      @apply p-0 m-0 text-sm text-primary-400;
    }
    .price {
      @apply text-white;
      span {
        @apply text-xl font-thin;
        text-shadow: #000 0px 1px 5px;
      }
      strong {
        @apply text-3xl;
        text-shadow: #000 0px 1px 5px;
      }
    }
  }
  .map-box-height {
    @apply relative;
    height: calc(100vh - 41px);
    height: calc(100svh - 41px);
    @media screen and (max-width: 800px) {
      width: 100%;
      overflow: hidden;
    }
  }

  .padding-zoom-map {
    padding-bottom: 3%;
    padding-left: 10%;
    @media screen and (max-width: 500px) {
      padding-left: 14%;
      padding-bottom: 12%;
    }
  }

  .map-frame {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    border-top-right-radius: 5%;
    // border-bottom-right-radius: 20%;
    height: 100%;
    padding-bottom: 3%;
    padding-right: 1%;
    padding-top: 1%;
    padding-left: 10%;
    margin: 0;

    @media screen and (max-width: 500px) {
      border-radius: 10%;
      border-bottom-left-radius: 8%;
      padding: 0;
      width: 75%;
      height: 87%;
      margin-left: 13%;
      margin-top: 14%;
    }
  }

  .map-frame-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 500px) {
      margin-top: -3rem;
    }
  }

  .map-frame-container::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 65%;
    background: url('/images/map-frame.svg') no-repeat top center;
    background-size: contain;

    @media screen and (max-width: 500px) {
      content: '';
      display: block;
      width: 100%;
      padding-top: 188%;
      background: url('/images/map-frame-mobile.png') no-repeat top center;
      background-size: contain;
    }
  }

  .map-tooltip-container {
    max-height: 70vh;
    padding-right: 2px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .text-container {
    white-space: nowrap; /* Asigură că textul nu se învârtește pe rândul al doilea */
    overflow: hidden; /* Ascunde textul care depășește containerul */
    text-overflow: ellipsis; /* Înlocuiește textul ascuns cu "..." */
    width: 100%; /* Asigură că textul se va încadra în containerul dorit */
  }
  .mapboxgl-marker {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
  }
  .search-box {
    @apply absolute bottom-5  z-50 w-full;
    max-width: 400px;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    transition: all 400ms ease-in-out 10ms !important;

    &.is-open {
      left: 70%;
    }
    @media screen and (max-width: 800px) {
      top: 70px;
      bottom: auto;
    }
    p {
      @apply text-sm;
    }
    .box-inner-search {
      @apply bg-white px-6 py-2 w-full;
    }
  }

  .login-page {
    background: url('/images/login-bg.svg') left center no-repeat;
    background-size: contain;

    .left-animation {
      max-height: calc(100vh - 6rem);
    }
    @media screen and (max-width: 901px) {
      background: url('/images/login-bg-mobile.svg') left top no-repeat;
      .left-animation {
        max-height: 23vh;
      }
    }
  }
  .shadow-top-border {
    // box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  }
  .phone-input.react-tel-input {
    font-family: 'Raleway', 'sans-serif';
    input {
      @apply text-base bg-transparent placeholder-transparent outline-none rounded-none text-black dark:text-white font-semibold h-unset w-full pl-14 pr-4 py-2 border-0 border-grey-100 dark:border-bordercolordark dark:border-opacity-40 border-t-0 border-l-0 border-r-0 border-b-3 animated focus:ring-0 hover:border-primary-500 focus:border-primary-500;
    }
    .flag-dropdown {
      @apply top-2 bottom-2 bg-transparent border border-body dark:border-bordercolordark;
      border-radius: 6px !important;

      .selected-flag {
        border-radius: 6px !important;

        .open {
          background: red;
        }
      }

      .arrow {
        border: 0;
        width: 5px;
        height: 5px;
        background: url('/images/icons/forward.svg') center center no-repeat;
        transition: all 0.3s ease-in-out;
        &.up {
          border: 0;
          transform: rotate(-180deg);
        }
      }
      .country-list {
        border: 0;
        // background: #ffffff;
        // box-shadow: 0px -1px 10px rgba(154, 170, 207, 0.15), 0px 16px 40px rgba(154, 170, 207, 0.15);
        border-radius: 20px;
        overflow: 'hidden';

        li {
          color: var(--dark);
          font-weight: 500;
          font-size: 0.875rem;
          line-heigth: 1.4;

          @apply dark:text-dark-400;

          .dial-code {
            @apply dark:text-dark-300;
          }
        }

        li:hover {
          @apply dark:bg-dark-100;
        }

        @apply bg-white dark:bg-dark-200;
      }
    }

    .special-label {
      @apply block text-sm dark:bg-dark-200 dark:text-dark-400 text-body absolute animated origin-0 top-unset bottom-3.5 left-3.5 transform -translate-y-8 pointer-events-none;
    }
  }

  .react-tel-input .special-label {
    display: block !important;
    top: -18px !important;
    left: 0 !important;
  }

  .react-tel-input .country-list .country.highlight {
    background-color: #858d96 !important;
  }

  #nav-icon1 {
    @apply absolute top-3  md:top-5  right-2 w-8 h-6 block md:hidden transform rotate-0 ease-in-out  cursor-pointer duration-500;
    span {
      @apply block absolute h-1 w-full bg-primary-700 rounded-md opacity-100 left-0 transform rotate-0 ease-in-out duration-150;
    }
    span:nth-child(1) {
      @apply top-0;
    }
    span:nth-child(2) {
      @apply top-2;
    }
    span:nth-child(3) {
      @apply top-4;
    }
    &.open {
      span:nth-child(1) {
        @apply top-2;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      span:nth-child(2) {
        @apply opacity-0 -left-10;
      }
      span:nth-child(3) {
        @apply top-2;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
  .popover-handler {
    span {
      svg {
        @apply mx-auto;
      }
    }
  }
  .tps-popover {
    .MuiPopover-paper {
      @apply rounded-3xl bg-white dark:shadow-none dark:dark-shadow;
      box-shadow:
        0px -1px 10px rgba(154, 170, 207, 0.15),
        0px 16px 40px rgba(154, 170, 207, 0.15);
    }
  }
  .empty-space {
    @apply bg-grey-500 rounded bg-opacity-20 h-5 relative;
  }
  .is-center-box {
    label {
      @apply text-center justify-center;
    }
  }

  .carousel .slide img,
  .carousel-photo {
    @apply object-cover w-full;
  }
  .h-full .carousel .slide img,
  .carousel-photo {
    @apply object-cover w-full;
  }

  .scroll-btn {
    width: 20px;
    height: 20px;
  }

  .mainLayout .scheduler-main-box .scheduler .scheduler-table tr td {
    height: 22px !important;
    width: 3.3% !important;
    min-width: 30px !important;
  }
  .mainLayout .scheduler-main-box .scheduler .top-legend {
    font-size: 12px;
    color: #fff;
    transform: rotate(-70deg);
    width: 50px !important;
    height: 25px;
    position: absolute;
    top: -36px;
    left: -5px;
  }
  .mainLayout .scheduler-main-box .scheduler {
    width: 100%;
    max-width: 100%;
  }
  .schedulerBox {
    width: 100%;
    @media screen and (max-width: 800px) {
      overflow: visible;
      max-width: 211px;
      overflow-y: scroll;
      padding-top: 40px;
      margin-top: -40px;
      width: 100%;
      margin-left: 30px;
    }
  }

  .mainLayout .scheduler-main-box .scheduler .scheduler-table table {
    width: calc(100% - 100px) !important;
    @media screen and (max-width: 800px) {
      width: auto !important;
    }
    &.read-only {
      width: 100% !important;
    }
  }
  .center-box-height {
    position: absolute;
    top: 50%;

    transform: translate(0, -50%);
    @media screen and (max-width: 800px) {
      transform: translate(-50%, -50%);
      left: 50%;
      width: 100% !important;
    }
  }
  .left-arrow {
    @apply absolute left-0 top-2/4 z-50 text-lightGrey-500;
    transform: translate(87px, -50%);
  }
  .right-arrow {
    @apply absolute right-2 top-2/4 z-50 text-lightGrey-500;
    transform: translate(0, -50%);
  }
  .box-upload {
    @apply flex flex-col;
    min-height: 300px;
    .drop-container {
      @apply flex-1 w-full  relative z-40 border-dashed border border-lightGrey dark:border-bordercolordark rounded-xl bg-f0f0f0 bg-opacity-40 dark:bg-dark-200;
      min-height: 300px;
      &.is-over {
        @apply border-primary-500 bg-primary-500 bg-opacity-10;
      }
    }
  }
  .ico-notification {
    @apply relative cursor-pointer;
    &:after {
      content: '';
      @apply absolute -top-0.5 right-1 w-2 h-2 rounded-half bg-transparent  transition duration-300;
    }

    &.active {
      &:after {
        @apply bg-primary-500;
      }
    }
  }

  .arrow-down::after {
    content: '';
    @apply absolute w-0 h-0 left-2/4 -translate-x-2/4 -bottom-1;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgb(59, 73, 88);
  }
}

.container-sticky {
  z-index: 99;
  position: relative;
  width: 100%;
}

.fixed-nav {
  width: 100%;
}

.container-title-mobile {
  width: 100%;
  padding-left: 1rem;
  height: 5rem;
  align-items: center;
  margin-bottom: 5px;
}

.border-gray {
  border: 2px solid #f4f4f4;
}

.margin-4rem {
  margin-top: 4rem;
}

.relative .scheduler-main-box .scheduler .scheduler-table tr td.cell-partial {
  background: #ff9800 !important;
}
.relative .scheduler-main-box .scheduler .scheduler-table tr td.cell-partial.selected {
  background: #e18d0f !important;
}
.tooltip .tooltiptext {
  @apply text-sm;
}

.remove-mb-0 {
  margin-bottom: -0.5rem;
}
.review-steps {
  li {
    @apply flex justify-between py-2;
    span,
    strong {
      @apply text-base px-1;
    }
    strong {
      @apply text-base;
    }
  }
}
.classic-tooltip.show {
  @apply bg-dark-default border  px-4 py-3 font-semibold text-sm text-white;
  border-radius: 20;
  border: 1px solid #252f3a;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);

  &.place-right::after,
  &.place-left::after,
  &.place-top::after,
  &.place-bottom::after {
    border-right-color: #3b4958;
  }
}
// @media screen and (max-width: 800px) {
//   .search-input-box {
//     position: absolute;
//     right: -30px;
//     width: calc(100vw - 40px);
//     top: 50px;
//   }
// }
.gradient-overlay {
  width: 100%;
  height: 60%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  pointer-events: none;
  &:before {
    content: '';
    @apply absolute left-0 top-0 rounded-b-6xl  w-full h-4/6 bg-black bg-opacity-10 blur-sm;
  }
}
.stripe-warning-message {
  border: 3px solid;
  color: rgb(0, 0, 0);
  font-weight: 900;
  padding: 0.3rem;
}
.card-stripe .field {
  padding: 0px 20px 11px;
  background-color: transparent;
  border-radius: 2px;
  width: 100%;
  color: rgb(255, 255, 255);
}
.stripe-close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2rem;
  border: 2px solid;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 1.2;
  border-radius: 20px;
  cursor: pointer;

  @apply dark:border-dark-300 dark:text-dark-300;
}

.sliders {
  .slider-wrapper {
    padding: 10px 0;
    position: relative;
  }
  .item {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    list-style: none;
    width: 25%;
    flex: 0 0 25%;
    margin-right: 1%;
    background-color: black;
    cursor: pointer;
    transition-duration: 0.5s;
    height: 160px;
    @media screen and (min-width: 1500px) {
      height: 230px;
    }

    .box-hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 99;
      pointer-events: none;
      transition-duration: 0.5s;
      .other-details {
        @apply bg-white w-full top-full absolute px-4;
        height: 154px;
        overflow: hidden;
        z-index: 999;
        transition-duration: 1s;
      }
    }

    &:hover {
      .box-hover {
        transition-delay: 0.5s;
        opacity: 1;
        pointer-events: all;
        transform: translate3d(0px, -20px, 0) scale(1.1);
      }
    }
    &.key-0 {
      &:hover {
        .box-hover {
          transition-delay: 0.5s;
          opacity: 1;
          pointer-events: all;
          transform: translate3d(19px, -20px, 0) scale(1.1);
        }
      }
    }
    &.key-4 {
      &:hover {
        .box-hover {
          transition-delay: 0.5s;
          opacity: 1;
          pointer-events: all;
          transform: translate3d(-19px, -20px, 0) scale(1.1);
        }
      }
    }
    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      h2 {
        @apply px-4 font-semibold;
        color: #fff;
        position: absolute;
        bottom: 0;
        z-index: 2;
        line-height: 1;
        span {
          @apply text-sm font-normal;
        }
      }
      &:before {
        content: '';
        width: 100%;
        display: none;
        z-index: 1;
        pointer-events: none;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
      }
    }
    .bg-img {
      position: absolute;
      width: 100%;
      height: 100%;
      max-height: 160px;
      background-size: contain;
      background-position: center top;
      @media screen and (min-width: 1500px) {
        height: 230px;
        max-height: 230px;
      }
    }
  }

  .slide-button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 55px;
    background: rgba(0, 0, 0, 0.5);
    border: 0;
    outline: 0;
    padding: 0;
    margin: 40px 0;
    z-index: 4;
    @media screen and (max-width: 800px) {
      display: none;
    }

    span {
      width: 25px;
      color: #fff;
      display: block;
      margin: 0 auto;
    }

    &--next {
      right: 0;

      span {
        transform: rotateZ(0deg);
      }
    }

    &--prev {
      left: 0;

      span {
        transform: rotateZ(-180deg);
      }
    }
  }

  .slider {
    display: flex;
    position: relative;
    height: 160px;
    @media screen and (min-width: 1500px) {
      height: 230px;
    }

    &__container {
      display: flex;
      padding: 0 0px;
      transition: transform 300ms ease 100ms;
      z-index: 3;
      width: 100%;
      @media screen and (max-width: 800px) {
        overflow-y: scroll;
      }
    }
  }
}
.slider-item-info {
  @apply px-4 py-2;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(44, 62, 80, 0) 100%);
  width: 100%;
  height: 100%;
  h4 {
    @apply text-sm tracking-wider font-semibold uppercase text-white;
  }
}
.range {
  input[type='range'].range-slider {
    border-radius: 4px;
    height: 8px;
    background: #dedede;
    &::-webkit-slider-runnable-track {
      background: transparent;
      border-radius: 4px;
      height: 8px;
    }
    &.range-slider--danger::-webkit-slider-thumb {
      background: #f53d3f;
      /* margin-top: -8px; */
      width: 24px;
      height: 24px;
    }
  }
  .range-slider__wrap {
    height: 1.5rem;
    .range-slider__tooltip {
      &.range-slider__tooltip--bottom {
        top: 1.75rem;
        opacity: 1;
        .range-slider__tooltip__caret {
          &::before {
            border-bottom-color: #eceff5;
          }
          &::after {
            position: absolute;
            content: '';
            border-color: transparent;
            border-style: solid;
            left: 0.5px;
            bottom: -1px;
            border-width: 0 0.36rem 0.36rem;
            border-bottom-color: #fff;
          }
        }
      }
      .range-slider__tooltip__label {
        background: #fff;
        border: 1px solid #eceff5;
        color: var(--dark);
        font-weight: 600;
        font-size: 0.875rem;
      }
    }
  }
}

.range
  .range-slider__wrap
  .range-slider__tooltip
  .range-slider__wrap
  .range-slider__tooltip
  .range-slider__tooltip__caret
  .main-sidebar {
  z-index: 9999;
}
.t1 {
  transition:
    max-width 400ms ease-in-out 0s,
    opacity 400ms linear 0s !important;
}
// .box-list-map {
//   position: relative !important;
//   width: 100% !important;
//   z-index: 40 !important;
//   background: rgb(255, 255, 255) !important;
//   transition: -ms-transform 850ms cubic-bezier(0.25, 1, 0.5, 1) 0s,
//     -webkit-transform 850ms cubic-bezier(0.25, 1, 0.5, 1) 0s, transform 850ms cubic-bezier(0.25, 1, 0.5, 1) 0s !important;
//   height: calc(100% - 120px);
//   max-width: 40vw;
//   @media screen and (max-width: 1400px) {
//     max-width: 50vw;
//   }
//   &.is-hidden {
//     transform: translateX(-100%) !important;
//   }
// }
// .map-box-area {
//   left: auto !important;
//   width: 100%;
//   right: 0;
//   transition: all 850ms cubic-bezier(0.25, 1, 0.5, 1) 0s !important;
//   &.is-hidden {
//     width: 60%;
//   }
// }
.h-calc-1 {
  height: calc(100vh - 169px);
  padding-bottom: 40px;
  .carousel .slide img,
  .carousel-photo {
    @apply object-cover w-full;
    height: 190px;
  }
  .h-full .carousel .slide img,
  .carousel-photo {
    @apply object-cover w-full;
    height: auto;
  }
}
.has-small-button {
  .soldout {
    display: none;
  }
  // button {
  //   width: 40px;
  //   height: 40px;
  //   bottom: auto;
  //   top: 10px;
  // }
  svg {
    width: 20px;
    height: 20px;
  }
}

// .is-open-list {
//   & + .the-tooltip {
//     left: 50%;
//     transform: translate(-50%, -50%);
//     z-index: 99;
//   }
// }
.soldout-bg::after {
  content: '';
  @apply absolute left-0 top-0 right-0 bottom-0 bg-dark-default bg-opacity-20 backdrop-filter backdrop-blur-sm -z-1;
}
.soldout {
  @apply shadow-sm z-50;
  margin: 0 !important;
  // height: 140px;
  img {
    height: 100%;
    object-fit: contain;
  }
}

.screen-buttons {
  @apply space-x-2 w-full;
  button {
    width: 50%;
    line-height: 2;
  }
}

.publish-width {
  @apply max-w-2xl mx-auto;
}

.pt-custom-h {
  padding-top: 68px;
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
.tabs {
  @apply overflow-x-auto;

  .link.active {
    @apply relative font-bold;
    &::after {
      content: '';
      @apply absolute bottom-0 left-7 w-10 h-0.5 bg-primary-500 rounded-3xl;
    }
  }
}

@keyframes expandWidth {
  0% {
    width: 10%;
  }
  100% {
    width: 100%;
  }
}

.expanding {
  animation: expandWidth 1.5s ease-out;
}

.preview-label {
  margin-top: 1px;
  font-weight: 700;
}

.map-layers-handler {
  max-height: 2.5rem;
  transition: max-height 0.7s;
  flex-wrap: nowrap;
}

.map-layers-handler:hover {
  max-height: 12rem;
  transition: max-height 0.7s;
}

.preview-image-handler {
  max-width: 2.5rem;
  transition: max-width 0.7s;
  flex-wrap: nowrap;
}

.preview-image {
  width: 85px;
  height: 85px;
  cursor: pointer;
  object-fit: cover;
}

.preview-image-handler:hover {
  max-width: 12rem;
  transition: max-width 0.7s;
}

.notifications-container-with {
  width: 460px;
  right: -490px;

  @media screen and (max-width: 795px) {
    width: 360px;
    right: -390px;
  }

  @media screen and (max-width: 625px) {
    width: 290px;
    right: -320px;
  }

  @media screen and (max-width: 490px) {
    width: 250px;
    right: -280px;
  }

  @media screen and (max-width: 380px) {
    width: 200px;
    right: -230px;
  }
}

.unread-notification {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  @apply bg-primary-500;
}

.preview-modal-md {
  height: 80vh;
  width: 60vw;
}

.survey-modal-grid {
  @apply grid-cols-2;
  @media screen and (max-width: 620px) {
    @apply grid-cols-1;
  }
}

.modal-max-h {
  max-height: 80vh;
}

.css-mvc5n8-placeholder {
  @apply dark:text-dark-500;
}
.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Adjust if needed */
  // background-color: white; /* Ensure it has a background color */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
  @apply bg-dark-default pb-2 pt-4;
}

.preview-modal {
  height: 80vh;
  width: 80vw;

  @media screen and (max-width: 1500px) {
    height: 70vh;
  }

  // @media screen and (max-width: 1200px) {
  //   height: 60vh;
  // }

  // @media screen and (max-width: 1000px) {
  //   height: 50vh;
  // }
}

.scroll-btn-left:after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  width: 100px;
  height: 100%;
  background: linear-gradient(to left, transparent, rgba(26, 26, 26, 1));
  pointer-events: none;
}

.scroll-btn-right:before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  width: 100px;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(26, 26, 26, 1));
  pointer-events: none;
}

.scroll-btn-left-light:after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  width: 100px;
  height: 100%;
  background: linear-gradient(to left, transparent, rgba(255, 255, 255, 1));
  pointer-events: none;
}

.scroll-btn-right-light:before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  width: 100px;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 1));
  pointer-events: none;
}

.scroll-btn-filter-left:after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  width: 100px;
  height: 100%;
  background: linear-gradient(to left, transparent, rgba(12, 12, 12, 1));
  pointer-events: none;
}

.scroll-btn-filter-left-light:after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  width: 100px;
  height: 100%;
  background: linear-gradient(to left, transparent, rgba(244, 244, 244, 1));
  pointer-events: none;
}

.scroll-btn-filter-right:before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  width: 100px;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(12, 12, 12, 1));
  pointer-events: none;
}

.scroll-btn-filter-right-light:before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  width: 100px;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(244, 244, 244, 1));
  pointer-events: none;
}

.skeleton {
  background: linear-gradient(90deg, hsla(213, 8%, 77%, 0.1), hsla(213, 8%, 77%, 0.3), hsl(213, 8%, 77%, 0.1));
  background-size: 200%;
  animation: loader 2s infinite reverse;
}

@keyframes loader {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 100% 0;
  }
}

.carousel-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.25rem 1rem;

  @media screen and (max-width: 1000px) {
    padding: 1rem;
  }
}

.carousel.carousel-slider .control-arrow:hover {
  // background: white !important;
  @apply bg-white dark:bg-dark-default;
}

.carousel-image {
  width: auto !important;
  max-height: 100% !important;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  max-height: 100% !important;
}

.carousel .slider-wrapper {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.carousel.carousel-slider .control-arrow {
  top: 50% !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 100% !important;
  box-shadow: 0px 5px 15px 0px #00000063 !important;
  margin: 0 !important;
  font-size: 0px !important;

  @media screen and (max-width: 800px) {
    width: 25px !important;
    height: 25px !important;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.carousel .thumbs-wrapper {
  text-align: center !important;
  margin-bottom: 0 !important;
}

.thumbs {
  padding: 10px 0px !important;
}

.carousel .thumb {
  padding: 0 !important;
  border-radius: 1rem !important;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 0 !important;
  box-shadow: 0px 5px 10px 0px #0814207a !important;
}

.carousel .control-next.control-arrow {
  right: 15px !important;
}

.carousel .control-prev.control-arrow {
  left: 15px !important;
}

.carousel .control-next.control-arrow:before {
  content: '→' !important;
  border: 0 !important;
  font-size: 30px !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;

  @media screen and (max-width: 800px) {
    font-size: 20px !important;
  }

  @apply dark:text-primarydark-default text-body;
}

.carousel .control-prev.control-arrow:before {
  content: '←' !important;
  border: 0 !important;
  font-size: 30px !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;

  @media screen and (max-width: 800px) {
    font-size: 20px !important;
  }

  @apply dark:text-primarydark-default text-body;
}

.carousel .thumb {
  height: 85px !important;

  @media screen and (max-width: 800px) {
    width: 50px !important;
    height: 55px !important;
  }

  img {
    height: 85px !important;

    @media screen and (max-width: 800px) {
      height: 55px !important;
    }
  }

  @apply dark:border-dark-200;
}

.preview-media-tooltip {
  padding: 0.75rem !important;
  border-radius: 1rem !important;
  background: #ffffff !important;
  color: #000000;
  font-weight: 600;
  border: 1px solid rgba(193, 197, 202, 1) !important;
  // --tw-shadow: 0px 16px 40px rgba(154, 170, 207, 0.2);
  // box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  // @apply max-lg:hidden;
  &.show {
    opacity: 1;
  }
  &.place-top {
    margin-top: -10px !important;
    &::after {
      border-top-color: rgba(255, 255, 255, 1) !important;
    }
  }
}

.custom-tooltip {
  background: transparent !important;
}

.condition-text-tooltip {
  overflow: hidden;
  white-space: nowrap;
}

.onboarding-text-tooltip {
  max-width: 400px;
}

.row-tooltip-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.__react_component_tooltip.place-top::before {
  display: none !important;
  bottom: 0px !important;
  border-top-color: rgba(193, 197, 202, 1) !important;
}
.__react_component_tooltip.place-top::after {
  display: none !important;
  bottom: 0px !important;
  border-top-color: rgba(193, 197, 202, 1) !important;
}

.__react_component_tooltip.place-left::before {
  display: none !important;
  border-left-color: rgba(193, 197, 202, 1) !important;
  right: 13px !important;
}
.__react_component_tooltip.place-left::after {
  display: none !important;
  border-left-color: rgba(193, 197, 202, 1) !important;
  right: 13px !important;
}

.__react_component_tooltip.place-right::before {
  display: none !important;
  border-right-color: rgba(193, 197, 202, 1) !important;
}
.__react_component_tooltip.place-right::after {
  display: none !important;
  border-right-color: rgba(193, 197, 202, 1) !important;
}

.__react_component_tooltip.place-bottom::before {
  display: none !important;
  border-bottom-color: rgba(193, 197, 202, 1) !important;
  top: 0 !important;
}
.__react_component_tooltip.place-bottom::after {
  display: none !important;
  border-bottom-color: rgba(193, 197, 202, 1) !important;
  top: 0 !important;
}

.preview-loader-container {
  border: 1px solid rgba(236, 239, 245, 1);
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container-gif {
  background-color: #000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.light-box-container {
  margin: 0 !important;
}

.carousel-root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carousel .slide img {
  max-height: 100%;
  width: auto;
}

.image-gallery {
  max-width: 500px;
}
.carousel-slider.carousel {
  width: auto !important;
  height: 100%;
  // padding-bottom: 1rem;
  .slide {
    transition: transform 300ms;
    &:not(.selected) {
      transform: scale(0.7);
    }
  }
  .control-dots {
    bottom: 0.25rem;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    .dot {
      margin: 0;
      background: #dedede;
      width: 0.4rem;
      height: 0.4rem;
      &.selected {
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        background: var(--dark);
      }
    }
  }
}

.mapboxgl-popup-tip {
  display: none !important;
}

.mapboxgl-popup {
  z-index: 99 !important;
  .mapboxgl-popup-content {
    @apply bg-white dark:bg-dark-200 rounded-3xl shadow-card dark:text-dark-300 p-0 font-semibold;
  }
}

.slideWrapper {
  display: flex;
  justify-content: center;
}

.prevArrow {
  position: absolute;
  top: 40%;
  left: 60px;
  z-index: 100;
  cursor: pointer;
  font-size: 2rem;
}

.nextArrow {
  position: absolute;
  top: 40%;
  right: 60px;
  z-index: 100;
  cursor: pointer;
  font-size: 2rem;
}

.media-card.active {
  position: relative;
  &::after {
    content: '';
    @apply rounded-3xl;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: -1;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background-size: 300% 300%;
    background-position: 0 50%;
    animation: moveGradient 6s alternate infinite;
  }
  &.pending:after {
    background-image: linear-gradient(
      60deg,
      var(--orange),
      var(--orangeOp),
      transparent,
      var(--orange),
      var(--orangeOp),
      transparent
    );
  }
  &.success:after {
    background-image: linear-gradient(
      60deg,
      var(--green),
      var(--greenOp),
      transparent,
      var(--green),
      var(--greenOp),
      transparent
    );
  }
}

.notification-wrapper {
  position: fixed;
  top: 45px;
  right: 20px;
  z-index: 100;
}

.custom-notification-width {
  width: 470px !important;

  @media screen and (max-width: 560px) {
    width: 400px !important;
  }

  @media screen and (max-width: 436px) {
    width: 300px !important;
  }

  @media screen and (max-width: 327px) {
    width: 250px !important;
  }
}

.notification {
  background-color: #1a1a1a;
  border-radius: 4px;
  width: 400px;
  margin-top: 16px;
  display: flex;
  position: relative;
  cursor: pointer;
  @apply dark:bg-dark-200 bg-white rounded-2xl p-4;
  animation-delay: 2500ms;

  @media screen and (max-width: 800px) {
    width: 300px;
  }

  @media screen and (max-width: 350px) {
    width: 250px;
  }

  &::after {
    content: '';
    @apply rounded-2xl;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: -1;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background-size: 300% 300%;
    background-position: 0 50%;
    animation: moveGradient 6s alternate infinite;
  }
  &.error_class {
    &:after {
      background-image: linear-gradient(
        60deg,
        var(--danger),
        var(--dangerOp),
        transparent,
        var(--danger),
        var(--dangerOp),
        transparent
      );
    }
  }
  &.giveaway_class {
    &:after {
      background-image: linear-gradient(
        60deg,
        var(--green),
        var(--greenOp),
        transparent,
        var(--green),
        var(--greenOp),
        transparent
      );
    }
  }
  &.warning_class {
    &:after {
      background-image: linear-gradient(
        60deg,
        var(--orange),
        var(--orangeOp),
        transparent,
        var(--orange),
        var(--orangeOp),
        transparent
      );
    }
  }
  &.info_class {
    &:after {
      background-image: linear-gradient(
        60deg,
        var(--info),
        var(--infoOp),
        transparent,
        var(--info),
        var(--infoOp),
        transparent
      );
    }
  }
}

.notification__text {
  padding: 16px 24px;
  line-height: 1.4;
}

.notification__close-btn {
  border: none;
  background-color: transparent;
  font-size: 20px;
  margin-top: 0px;
  margin-right: 8px;
  cursor: pointer;
}

.Toastify__toast-container {
  &.Toastify__toast-container--top-right {
    @apply z-999999;
  }
  .Toastify__toast {
    background-color: #fff !important;
    // min-height: 94px !important;
    border-radius: 20px !important;
    display: flex;
    overflow: visible;
    padding: 0 !important;
    box-sizing: border-box;
    font-family: 'Raleway', 'sans-serif' !important;
    &::after {
      content: '';
      @apply rounded-3xl;
      position: absolute;
      top: -2px;
      left: -2px;
      z-index: -1;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      background-size: 300% 300%;
      background-position: 0 50%;
      animation: moveGradient 6s alternate infinite;
    }
    &.error_class {
      &:after {
        background-image: linear-gradient(
          60deg,
          var(--danger),
          var(--dangerOp),
          transparent,
          var(--danger),
          var(--dangerOp),
          transparent
        );
      }
    }
    &.success_class {
      &:after {
        background-image: linear-gradient(
          60deg,
          var(--green),
          var(--greenOp),
          transparent,
          var(--green),
          var(--greenOp),
          transparent
        );
      }
    }
    &.warning_class {
      &:after {
        background-image: linear-gradient(
          60deg,
          var(--orange),
          var(--orangeOp),
          transparent,
          var(--orange),
          var(--orangeOp),
          transparent
        );
      }
    }
    &.info_class {
      &:after {
        background-image: linear-gradient(
          60deg,
          var(--info),
          var(--infoOp),
          transparent,
          var(--info),
          var(--infoOp),
          transparent
        );
      }
    }
    .Toastify__toast-body {
      @apply bg-white dark:bg-dark-200;

      height: 110px;
      display: flex;
      z-index: 3;
      position: relative;
      padding: 0 30px;
      border-radius: 20px !important;
    }
    .Toastify__close-button {
      position: absolute;
      right: 5px;
      z-index: 10;
      top: 0;
    }
  }
}

.avatar svg {
  @apply transition-all duration-300 ease-in-out;
}

.Toastify__toast-container {
  width: 400px !important;
  height: 95px;
  @media screen and (max-width: 800px) {
    width: 300px !important;
  }
}

.Toastify__close-button--default {
  color: #a7acb2 !important;
  opacity: 1 !important;
  margin-top: 10px;
  margin-right: 10px;
}
@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*override scheduler*/
.mainLayout .page-section .scheduler-main-box .scheduler .top-legend,
.mainLayout .page-section .scheduler-main-box .scheduler .left-legend .left-legend-item .inner {
  color: #3b4958;
  font-weight: 500;
  height: 30px;
  @apply dark:text-dark-300;
}

// .mainLayout .page-section .scheduler-main-box .left-arrow,
// .mainLayout .page-section .scheduler-main-box .right-arrow {
//   display: none;
// }

.mainLayout .page-section .scheduler-main-box .scheduler .left-legend .left-legend-item {
  width: 110px !important;
  height: 30px !important;
}

.mainLayout .page-section .schedulerBox {
  flex: 1;
}

.mainLayout .page-section .scheduler-main-box .scheduler .scheduler-table table {
  width: 100% !important;
}

.mainLayout .page-section .scheduler-main-box .scheduler .scheduler-table tr td.weekend {
  background: #e3e3e3;
  @apply dark:bg-dark-300 dark:bg-opacity-20;
}

.mainLayout .page-section .scheduler-main-box .scheduler .scheduler-table tr td {
  background: #ededed;
  border: 1px solid #c1c5ca;
  height: 30px !important;
  @apply dark:bg-dark-300 dark:bg-opacity-50;
}

.adjust-menu-alert {
  margin-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .container-sticky {
    position: fixed;
    width: 100%;
    top: 40px;
  }
}

@media screen and (max-width: 550px) {
  .row-tooltip-container {
    flex-wrap: wrap;
  }

  .custom-tooltip {
    width: 280px;
  }
}

@media screen and (max-width: 767px) {
  .kpis-bar {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .margin-4rem {
    margin-top: 1rem;
  }
  .scheduler-main-box {
    padding-top: 3rem;
  }
  .mainLayout .page-section .scheduler-main-box .left-arrow,
  .mainLayout .page-section .scheduler-main-box .right-arrow {
    position: absolute;
    top: -9rem;

    svg {
      color: #a7acb2;
    }
  }

  .mainLayout .page-section .scheduler-main-box .right-arrow {
    right: 1rem;
  }

  .mainLayout .page-section .scheduler-main-box .left-arrow {
    right: 3.5rem;
  }

  .mainLayout .page-section .scheduler-main-box .scheduler .left-legend .left-legend-item {
    width: 50px !important;
    height: 25px !important;
  }
  .mainLayout .page-section .scheduler-main-box .scheduler .top-legend,
  .mainLayout .page-section .scheduler-main-box .scheduler .left-legend .left-legend-item .inner {
    line-height: 1;
    font-size: 11px;
    white-space: break-spaces;
  }

  .mainLayout .page-section .scheduler-main-box .scheduler .top-legend,
  .mainLayout .page-section .scheduler-main-box .scheduler .left-legend .left-legend-item .inner,
  .mainLayout .page-section .scheduler-main-box .scheduler .scheduler-table tr td {
    height: 25px !important;
  }
  .mainLayout .scheduler-main-box .scheduler .scheduler-table tr td {
    min-width: 42px !important;
  }
}

.mainLayout .page-section .scheduler-main-box .scheduler .top-legend {
  left: 20%;
  /* transform: translateX(-30%) rotate(-70deg); */
}

.mainLayout .page-section .scheduler-main-box .scheduler .scheduler-table tr td.selected {
  background: #7ce0c6 !important;
}

.mainLayout .page-section .scheduler-main-box .scheduler .scheduler-table tr td.cell-disabled {
  background: #f53d3f !important;
}

.mainLayout .page-section .scheduler-main-box .scheduler .scheduler-table tr td.cell-partial {
  background: #fda53f !important;
}

@media screen and (max-width: 1023px) {
  .map-controls {
    right: 85%;
  }
  .menu-mobile-container {
    top: 55px;
  }
  .menu-mobile-icon {
    @apply w-12 h-12 block relative cursor-pointer;

    .menu-mobile,
    .menu-mobile::before,
    .menu-mobile::after {
      @apply bg-[#3b4958] dark:bg-dark-300;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      transition:
        background ease 0.3s,
        top ease 0.3s 0.3s,
        transform ease 0.3s;
      width: 20px;
      border-radius: 5px;
    }

    .menu-mobile {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &::before {
        width: 15px;
        top: -6px;
      }

      &::after {
        width: 10px;
        top: 6px;
      }

      .opened & {
        background: transparent;

        &::before,
        &::after {
          top: 0;
          width: 20px;
          transition:
            top ease 0.3s,
            transform ease 0.3s 0.3s;
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .page-section {
    z-index: 100;
  }
  .menu-opened + .page-section {
    top: 30%;
    left: 60%;
    box-shadow: -10px 20px 30px rgba(92, 96, 105, 0.15);
    border-radius: 25px;

    .container-sticky {
      top: 30%;
      width: 100%;
    }
  }

  .menu-opened + .page-section {
    .drac {
      width: calc(100vw - 19rem);
    }
  }
}

@media screen and (max-width: 767px) {
  .avatar-customize .kblZqt {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .overlay-map + .mapboxgl-map:after {
    content: '';
    @apply absolute top-0 left-0 w-full h-full z-100 bg-dark-default bg-opacity-20;
  }
  .campaign-kpis {
    justify-content: space-between;
    .bg-white.rounded-20 {
      flex: 0 0 47%;

      &:not(:first-child):not(:nth-child(2)) {
        @apply mt-7;
      }

      &:last-child {
        flex: 0 0 100%;
      }
    }
  }
  .mob-content {
    .transparent__menu {
      background: transparent;
      box-shadow: none;
      border-radius: 0;
    }
  }
  .mob-bullet {
    @apply relative;
    &:after {
      content: '';
      @apply absolute w-2 h-2 top-1/2 transform -translate-y-1/2 left-0 rounded-full;
    }
    &:nth-child(3n) {
      &:after {
        @apply bg-primary-500;
      }
    }
    &:nth-child(3n + 1) {
      &:after {
        @apply bg-accent-500;
      }
    }
    &:nth-child(3n + 2) {
      &:after {
        @apply bg-purpule-500;
      }
    }
  }
  .popover-handler.active {
    .active-handler {
      @apply border-primary-500 text-primary-500;
      svg path,
      svg circle {
        stroke: var(--primary);
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .navbar-hint {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .onboarding-switch-mobile {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .container-sticky {
    width: calc(100% - 9rem);
  }

  .fixed-nav {
    width: calc(100% - 9rem);
  }

  .menu-opened + .container-sticky {
    width: calc(100% - 18rem);
  }

  .menu-opened + .fixed-nav {
    width: calc(100% - 18rem);
  }

  .map-controls {
    right: 95%;
  }
  .display-alert {
    & ~ .page-section {
      padding-top: 4rem;
    }
    & ~ .menu-container {
      .menu-holder {
        @apply pt-0 md:pt-16;
      }
    }
  }
}

.pin-tooltip-top {
  top: -50px;
}

#hubspot-messages-iframe-container,#kommunicate-widget-iframe {
  transition: bottom 0.3s ease-in-out;
}


.bulk-buying #hubspot-messages-iframe-container,
.bulk-buying  #kommunicate-widget-iframe
{
  bottom: 8rem !important;
}

.publish #hubspot-messages-iframe-container,
.publish  #kommunicate-widget-iframe
{
  bottom: 8rem !important;
}
.publish  #kommunicate-widget-iframe
{
  bottom: 12rem !important;
}

@media screen and (max-width: 700px) {
  .publish #hubspot-messages-iframe-container,.publish #kommunicate-widget-iframe {
    max-height: 70vh !important;
    min-height: 100px;
  }
  .publish #kommunicate-widget-iframe{
    bottom:14rem!important;
  }
}
.completed-count-media {
  color: #2ecc71;
}

.uploading-count-media {
  color: #fda53f;
  margin-left: 5px;
}

.mobileabsolute {
  @apply absolute md:static left-20 z-50 max-h-100-40 shadow-card  top-[130px] bg-white dark:bg-dark-200 space-y-3 md:space-y-0 py-5 md:py-0 px-5 md:px-0 lg:flex md:space-x-2 lg:w-fit rounded-2xl;
}

.referral-icon-container {
  width: 5rem;
  height: 5rem;
}

.referral-container .referral-step-container:first-child {
  .referral-icon-container {
    img {
      transform: translateY(17px);
    }
  }
}

.referral-container .referral-step-container:nth-child(3n) {
  .referral-icon-container {
    img {
      transform: translateY(2px);
    }
  }
}

.center-bullet {
  width: 5px;
  height: 5px;
}

.border-bullet {
  width: 11px;
  height: 11px;
}

.newschedule-divider::before {
  content: '';
  position: absolute;
  top: 2;
  left: 0;
  height: 2px;
  width: 100%;
  @apply dark:bg-dark-400 dark:bg-opacity-20 bg-gray-300 bg-opacity-100;
}

.sync-divider::before {
  content: '';
  position: absolute;
  top: 2;
  left: 0;
  height: 2px;
  width: 100%;
  @apply dark:bg-dark-300 bg-dark-default;
}

.schedule-group-container::after {
  content: '';
  position: absolute;
  bottom: -1rem;
  left: -2rem;
  width: calc(100% + 4rem);
  height: 100px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f4f4f4 80.45%);
  pointer-events: none;
}

/* Dark mode */
.dark .schedule-group-container::after {
  background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #131313 80.45%);
}

.new-schedule-group-container::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f4f4f4 80.45%);
  pointer-events: none;
}

/* Dark mode */
.dark .new-schedule-group-container::after {
  background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #0a0909 80.45%);
}

@media screen and (min-width: 350px) {
  .count-basket-label-visibility {
    display: block;
  }
}

@media screen and (min-width: 400px) {
  .basket-label-visibility {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .more-filters-width {
    width: calc(50% - 2rem);
  }
}

@media screen and (max-width: 768px) {
  .more-filters-width {
    width: 100%;
  }
}

@media screen and (max-width: 379px) {
  .search-label {
    display: none;
  }
}

@media screen and (max-width: 978px) {
  .search-popover-mobile {
    display: block;
  }
}

@media screen and (max-width: 978px) {
  .search-popover-web {
    display: none;
  }
}
@media screen and (min-width: 978px) {
  .search-popover-mobile {
    display: none;
  }
}

@media screen and (min-width: 978px) {
  .search-popover-web {
    display: block;
  }
}

@media screen and (max-width: 1165px) and (min-width: 1022px) {
  .search-popover-mobile {
    display: block;
  }
}

@media screen and (max-width: 1165px) and (min-width: 1022px) {
  .search-popover-web {
    display: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  opacity: 0; /* Inițial, obiectul este invizibil */
  animation: fadeInRight 0.5s ease-in-out;
  animation-delay: 1s; /* Adăugăm o întârziere de 1 secundă */
  animation-fill-mode: forwards; /* Obiectul să rămână la starea finală a animației */
}

.box-shadow-container-light {
  box-shadow:
    0px -1px 10px rgba(154, 170, 207, 0.15),
    0px 16px 40px rgba(154, 170, 207, 0.15);
}

.sb-label {
  @apply hidden;
  @media screen and (min-width: 630px) {
    @apply inline;
  }
}

.sb-short-label {
  @apply inline;
  @media screen and (min-width: 630px) {
    @apply hidden;
  }
}

.website-title {
  font-size: 3rem;
}

.website-search-container {
  @apply w-full;
  @media screen and (min-width: 800px) {
    @apply w-11/12;
  }
  @media screen and (min-width: 900px) {
    @apply w-10/12;
  }
  @media screen and (min-width: 1000px) {
    @apply w-10/12;
  }
  @media screen and (min-width: 1200px) {
    @apply w-9/12;
  }
  @media screen and (min-width: 1800px) {
    @apply w-8/12;
  }
}

.website-map-container {
  @apply w-full;
  @media screen and (min-width: 630px) {
    @apply w-10/12;
  }

  @media screen and (min-width: 1000px) {
    @apply w-9/12;
  }

  @media screen and (min-width: 1500px) {
    @apply w-8/12;
  }

  @media screen and (min-width: 1900px) {
    @apply w-7/12;
  }
}

// @media screen and (max-width: 1175px) and (min-width: 768px) {
//   .basket-label-visibility {
//     display: none;
//   }
// }

// @media screen and (max-width: 1120px) and (min-width: 768px) {
//   .count-basket-label-visibility {
//     display: none;
//   }
// }

// @media screen and (max-width: 1090px) and (min-width: 1024px) {
//   .chevron-basket {
//     display: none;
//   }
// }

// @media screen and (max-width: 928px) and (min-width: 896px) {
//   .chevron-basket {
//     display: none;
//   }
// }

// @media screen and (max-width: 856px) and (min-width: 768px) {
//   .chevron-basket {
//     display: none;
//   }
// }

// @media screen and (max-width: 896px) and (min-width: 768px) {
//   .border-bullet {
//     display: none;
//   }
// }

// @media screen and (max-width: 1239px) and (min-width: 768px) {
//   .filters-label {
//     display: none;
//   }
// }

.monday-shadow {
  box-shadow: 0px 6px 48px 0px #000000a6;
}

.monday-gradient {
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 0.8) 30%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.4) 60%,
    rgba(0, 0, 0, 0.3) 70%,
    rgba(0, 0, 0, 0.2) 80%,
    rgba(0, 0, 0, 0.1) 90%,
    rgba(0, 0, 0, 0) 100%
  );
}

.monday-box-shadow {
  box-shadow: 0px 6px 48px 0px #000000a6;
}
.currency-input {
  @apply bg-transparent outline-none text-dark-default dark:text-white font-semibold w-full px-4 py-2 border-0 text-lg md:text-base border-t-0 border-l-0 border-r-0 border-b-2 animated focus:ring-0 dark:border-bordercolordark hover:border-primary-500 dark:hover:border-primary-500 focus:border-primary-500 dark:focus:border-primary-500;
}
.inner-text-blue {
  & > button,
  .text-white {
    @apply text-primary-500;
  }
}
.react-datepicker-wrapper input {
  @apply bg-transparent outline-none text-dark-default dark:text-white font-semibold w-full px-4 py-2 border-0 text-lg md:text-base border-t-0 border-l-0 border-r-0 border-b-2 animated focus:ring-0 dark:border-bordercolordark hover:border-primary-500 dark:hover:border-primary-500 focus:border-primary-500 dark:focus:border-primary-500;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
